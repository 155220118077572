import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";

function DesignStoryBlock({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Story Block" />
      <h2>Story Block</h2>
      <div className="design__stories">
        <DesignStory
          title="Storytelling Block"
          component={MediaBlock}
          type="media"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignStoryBlock.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignStoryBlock.defaultProps = {
  props: {},
};

export default DesignStoryBlock;
